<script>
export default {
    props: {
        field: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            hasFocus: false,
        }
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: (event) => this.$emit('input', event.target.value),
            }
        },
    },
}
</script>

<template>
    <div :class="{ hasFocus: hasFocus }">
        <BaseLabel :field="field" />
        <div class="file" :class="$style.input">
            <div v-if="field.placeholder" :class="$style.placeholder">
                {{ field.placeholder }}
            </div>
            <input
                :id="field.name"
                type="file"
                :name="field.name"
                accept="image/*"
                :required="field.required"
                v-on="listeners"
                @focus="hasFocus = true"
                @blur="hasFocus = false"
            />
        </div>
    </div>
</template>

<style lang="scss" module>
@import '@design';

.input {
    @include input;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    input {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}
.placeholder {
    max-width: 80%;
    text-align: center;
}
</style>
